import React, { useState,useEffect } from "react";
import { createContext } from "react";
import { BaseUrl } from "../Service/Apis/Apis";
import axios from "axios";

export const UserContext = createContext();
const Context = ({ children }) => {
 
  const [token, setToken] = useState([]);


  const getToken = async () => {
    try {
      const responsedata = await axios.get(BaseUrl + "api/admin/loginexplorer", {
      });
      const resp = responsedata?.data;
      if (resp.status === 200) {
        console.log(resp?.data, "<---------------- list resp>>>>>>>>");
       setToken(resp?.data?.token)
      } else {
        console.log(resp, "<--- error123");
        // fireToast("error", resp.error);
      }
    } catch (error) {
      console.log(error);
      // fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(()=>{
    getToken()
  },[])

  return (
    <UserContext.Provider
      value={{
        token,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default Context;