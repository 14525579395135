import React from "react";
import { Container } from "react-bootstrap";

const ChainCodes = () => {
  const listing = [
    {
      id: "3",
      channel_name: "Blank",
      blocks: "3",
      transactions: "53",
      timestamp: "2024-09-03T05:35:43.000Z",
    },
    {
      id: "4",
      channel_name: "centralauthority",
      blocks: "3",
      transactions: "53",
      timestamp: "2024-09-03T05:35:43.000Z",
    },
    {
      id: "5",
      channel_name: "mychannel",
      blocks: "3",
      transactions: "53",
      timestamp: "2024-09-03T05:35:43.000Z",
    },
  ];

  return (
    <React.Fragment>
      <div className="blocks_page py-5">
        <Container>
          <div className="rounded-3 bg-white  ml-4 p-3">
            <table className="table table-striped text-center chaincodes_table">
              <thead>
                <tr>
                  <th scope="col">
                    ID
                    <br />
                    <input type="search" />
                  </th>
                  <th scope="col">
                    Channel Name
                    <br />
                    <input type="search" />
                  </th>
                  <th scope="col">
                    Blocks
                    <br />
                    <input type="search" />
                  </th>
                  <th scope="col">
                    Transactions
                    <br />
                    <input type="search" />
                  </th>
                  <th scope="col">
                    Timestamp
                    <br />
                    <input type="search" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {listing &&
                  listing.map((e, index) => (
                    <tr key={index}>
                      <td>
                        <p className="font-size-banner-entries mb-1 main_heading">
                          {e.id}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.channel_name}
                        </p>
                      </td>
                      <td>
                        <p className=" blocks_page_td font-size-banner-entries mb-1">
                          {e.blocks}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.transactions}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.timestamp}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChainCodes;
