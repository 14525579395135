import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { access_token } from "../../../common/Toaster";
import axios from "axios";
import { BaseUrl } from "../../../Service/Apis/Apis";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
function Table() {
  const navigate = useNavigate();
  // const LatestBlock = [
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  //   {
  //     creator: "ORG1MSP",
  //     channelName: "Centeralauthority",
  //     txId: "369UIN203......",
  //     type: "NOT DEFINED",
  //     chainCode: "_lifecycle",
  //     timestamp: "2024-09-03T12:55:36.958Z",
  //   },
  // ];

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [latestBlock, setLatestBlock] = useState([]);
  const perPage = 10;

  const handlePageClick = (page) => {
    setCurrentPage(page);
    getBlockInformation(page, perPage);
  };

  const getBlockInformation = async (pagenumber, pagelimit) => {
    try {
      // setLoading(true);
      // const resp = await userListing(status, pageNumber, pageLimit);
      const responsedata = await axios.get(
        BaseUrl +
          `api/admin/transactionslist?page=${pagenumber}&size=${pagelimit}`,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );
      const resp = responsedata?.data;
      if (resp.status === 200) {
        console.log(resp?.data?.txnsData, "<---------------- list resp");
        setLatestBlock(resp?.data?.txnsData);
        setTotalCount(
          resp?.data?.noOfpages ? resp?.data?.noOfpages : totalCount
        );
      } else {
        console.log(resp, "<--- error");
        // fireToast("error", resp.error);
      }
    } catch (error) {
      console.log(error);
      // fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getBlockInformation(currentPage, perPage);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        <div className="border border_grey rounded-3 bg-white shadow-sm ml-4 p-3 my-5">
          <div className="text-start pb-4 fw-semibold">LATEST BLOCKS</div>
          <table className="table table-striped border border_grey text-center">
            <thead>
              <tr>
                <th scope="col">Creator</th>
                <th scope="col">Channel Name</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Type</th>
                <th scope="col">Smart Contract</th>
                <th scope="col">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {latestBlock &&
                latestBlock.map((e, index) => (
                  <tr key={index}>
                    <td>
                      <p className="font-size-banner-entries  mb-1">
                        {e.creator_msp_id}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.channelname}
                      </p>
                    </td>
                    <td>
                      <p
                        className="font-size-banner-entries pink mb-1"
                        onClick={() =>
                          navigate("/transactionDetail?id=" + e.txhash)
                        }
                        role="button"
                      >
                        {e.txhash}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">{e.type}</p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.chaincodename}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.createdt}
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <Button className="w-100 bg-light text-dark font-size-banner-entries">
            View All Latest Transactions
          </Button> */}
          <Row className="mt-2">
            <Col md={9}></Col>
            <Col md={3}>
              <Pagination
                {...bootstrap5PaginationPreset}
                current={currentPage}
                total={totalCount}
                onPageChange={(page) => handlePageClick(page)}
                className="pagination justify-content-end"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Table;
