import React from "react";
import "./explorer.css";
import Header from "./header";
import Expfooter from "./expfooter";
import Expsearch from "./expsearch";
import Expbanner from "./expbanner";

const index = () => {
  return (
    <React.Fragment>
      <Header />
      <Expsearch />
      <Expbanner />
      <Expfooter />
    </React.Fragment>
  );
};

export default index;
