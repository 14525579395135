import React, { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import header_logo from "../../Assets/Images/header_logo.png";
import { RiSettings3Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";

const Header = () => {
  useEffect(() => {}, []);
  return (
    <div>
      <div>
        <div>
          <Container className="d-flex justify-content-between mt-2">
            <div className="d-flex align-items-center ">
              <img src={header_logo} alt="header-logo" />
              <div className="ms-2">
                Faction Price:{" "}
                <span className="highlighted_text">$ 0.464+2.16 %</span>
              </div>
            </div>
            <button
              className="btn btn-outline-secondary"
              style={{
                backgroundColor: "var(--theme-primary-background-color)",
                color: "black",
              }}
            >
              <RiSettings3Line size={25} />
            </button>
          </Container>
        </div>
        <hr />
        <Navbar expand="lg" className="bg-white">
          <Container>
            <Navbar.Brand href="/">
              Faction Ai <span className="highlighted_text"> Thunder Hawk</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {/* <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#link">Link</Nav.Link> */}
                {/* <NavDropdown title="Blockchain" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Tokens" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Resources" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Developers" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#home">More</Nav.Link> */}

                <NavLink className="nav-link" to="/explorer">
                  DASHBOARD
                </NavLink>
                <NavLink className="nav-link" to="/latestTransactions">
                  TRANSACTIONS
                </NavLink>
                <NavLink className="nav-link" to="/smartcontractlist">
                  SMART CONTRACTS
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* <hr /> */}
      </div>
    </div>
  );
};

export default Header;
