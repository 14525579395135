import React from "react";
import { Container } from "react-bootstrap";

const Channels = () => {
  const listing = [
    {
      block_number: "15",
      channel_name: "My Channel",
      no_of_tx: "1",
      data_hash: "e836b7...",
      block_hash: "1c7fc4...",
      previous_hash: "a25926...",
      transactions: "73226f...",
      size: "6",
    },
    {
      block_number: "15",
      channel_name: "My Channel",
      no_of_tx: "1",
      data_hash: "e836b7...",
      block_hash: "1c7fc4...",
      previous_hash: "a25926...",
      transactions: "73226f...",
      size: "6",
    },
    {
      block_number: "15",
      channel_name: "My Channel",
      no_of_tx: "1",
      data_hash: "e836b7...",
      block_hash: "1c7fc4...",
      previous_hash: "a25926...",
      transactions: "73226f...",
      size: "6",
    },
  ];

  return (
    <React.Fragment>
      <div className="blocks_page py-5">
        <Container>
          <div className="channel_filters mb-4 d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="page_size_filter d-flex">
                <p className="mb-0">No of Blocks</p>
                <select>
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                </select>
              </div>
              <div className="block_number_filter gap-2">
                <p className="mb-0">Block No:</p>
                <input type="text" placeholder="From" />
                <input type="text" placeholder="To" />
              </div>
              <input
                className="search_filter"
                type="search"
                placeholder="Search"
              />
            </div>
            <div className="d-flex gap-2">
              <div className="page_size_filter d-flex">
                <p className="mb-0">Select Orgns</p>
                <select>
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                </select>
              </div>
              <button className="btn clear_filter_btn">Clear Filter</button>
            </div>
          </div>
          <div className="rounded-3 bg-white  ml-4 p-3">
            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th scope="col">Block Number</th>
                  <th scope="col">Channel Name</th>
                  <th scope="col">Number of Tx</th>
                  <th scope="col">Data Hash</th>
                  <th scope="col">Block Hash</th>
                  <th scope="col">Previous Hash</th>
                  <th scope="col">Transactions </th>
                  <th scope="col">Size (KB) </th>
                </tr>
              </thead>
              <tbody>
                {listing &&
                  listing.map((e, index) => (
                    <tr key={index}>
                      <td>
                        <p className="font-size-banner-entries mb-1 main_heading">
                          {e.block_number}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.channel_name}
                        </p>
                      </td>
                      <td>
                        <p className=" blocks_page_td font-size-banner-entries mb-1">
                          {e.no_of_tx}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.data_hash}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.block_hash}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.previous_hash}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.transactions}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.size}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Channels;
