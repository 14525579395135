import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Pages/ExplorerNew/header";
import ExplNavbar from "../../Pages/ExplorerNew/Navbar";
import Footer from "../../Pages/ExplorerNew/Footer";

const ExplorerLayout = () => {
  return (
    <div className="exp_layout">
      <Header />
      <ExplNavbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default ExplorerLayout;
