import React, { useState } from "react";
import "./latest.css";
import Table from "./table";
import Search from "./search";
import axios from "axios";
import mark from "../../../Assets/Images/mark.svg";
import { BaseUrl } from "../../../Service/Apis/Apis";
import { access_token } from "../../../common/Toaster";
import { Modal, Button } from "react-bootstrap";
const Index = () => {
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState({});
  const handleClose = () => {
    setSearch("");
    setShow(false);
  };
  const [search, setSearch] = useState("");
  const handleSearch = async (e) => {
    setSearch(e?.target?.value);
    console.log(e?.target?.value, "hghkj");
    const val = e?.target?.value;
    if (val) {
      const responsedata = await axios.get(
        BaseUrl + `api/admin/fetchdatabytxid?txhash=${val}`,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );
      const resp = responsedata?.data;
      if (resp.status === 200) {
        console.log(resp.data, "<<-------------------- search resp");
        setInfo(resp.data);
        setShow(true);
      }
    }
  };
  return (
    <React.Fragment>
      <Search search={search} handleInputChange={handleSearch} />
      <Table />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Transaction Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" p-3 border rounded bg-white shadow-sm font-size text-gray">
            <div className="d-flex padding-left1 padding-right1 align-items-center  mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Transaction ID:</p>
              </div>
              <div className="col-9">{info?.txhash}</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Validation Code:</p>
              </div>
              <div className="col-9">{info?.validation_code}</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Payload Proposal Hash:</p>
              </div>
              <div className="col-9 text-pink ">
                {info?.payload_proposal_hash}
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Creator MSP:</p>
              </div>
              <div className="col-9 ">{info?.creator_msp_id}</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Endorser:</p>
              </div>
              <div className="col-9 ">
                <span>{info?.endorser_msp_id}</span>
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Smart Contract Name:</p>
              </div>
              <div className="col-9 ">
                <span className="text-pink">{info?.chaincodename}</span>
              </div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Type:</p>
              </div>
              <div className="col-9 ">{info?.type}</div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Time:</p>
              </div>
              <div className="col-9 ">{info?.createdt}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Index;
