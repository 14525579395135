import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { access_token, fireToast } from "../../common/Toaster";
import axios from "axios";
import { BaseUrl } from "../../Service/Apis/Apis";

const Networks = () => {
  const navigate = useNavigate();
  const [listing, setListing] = useState([]);
  const getContractInfo = async () => {
    try {
      const responsedata = await axios.get(BaseUrl + `api/admin/peerstatus`, {
        headers: {
          Authorization: access_token,
        },
      });
      const resp = responsedata?.data;
      if (resp.status === 200) {
        setListing(resp.data);
        console.log(resp, "<<--------------------- resp networks");
      } else {
        fireToast("error", resp.error);
      }
    } catch (error) {
      console.log(error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getContractInfo();
  }, []);
  return (
    <React.Fragment>
      {/* <List/> */}
      <Container>
        <div className="border border_grey rounded-3 bg-white shadow-sm ml-4 p-3 my-5">
          {/* <div className="text-start pb-4 fw-semibold">LATEST BLOCKS</div> */}
          <table className="table table-striped border border_grey text-center">
            <thead>
              <tr>
                <th scope="col">Peer Name</th>
                <th scope="col">Request URL</th>
                <th scope="col">Peer Type</th>
                <th scope="col">MSPID</th>
                <th scope="col">High</th>
                <th scope="col">Low</th>
                <th scope="col">Unsigned</th>
              </tr>
            </thead>
            <tbody>
              {listing &&
                listing.map((e, index) => (
                  <tr key={index}>
                    <td>
                      <p
                        className="font-size-banner-entries mb-1"
                        onClick={() => navigate("/smartcontractdetails")}
                      >
                        {e.server_hostname}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.server_hostname}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.peer_type}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">{e.mspid}</p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.ledger_height_high}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.ledger_height_low}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.ledger_height_unsigned}
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <Button className="w-100 bg-light text-dark font-size-banner-entries">
            View All Latest Transactions
          </Button> */}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Networks;
