import React, { useEffect } from "react";
import AOS from "aos";
import "./App.css";
import LandingPage from "./Pages/LandingPage/index";
import TransactionDetail from "./Pages/TransactionDetail/index";
import Explorer from "./Pages/Explorer/index";
// import Explorer2 from "./Pages/ExplorerNew";
import Blocks from "./Pages/ExplorerNew/blocks";
import ChainCodes from "./Pages/ExplorerNew/chainCodes";
import Channels from "./Pages/ExplorerNew/channels";
import Networks from "./Pages/ExplorerNew/networks";
import Transactions from "./Pages/Transactions/index";
import Latest from "./Pages/ExplorerNew/Latest/index";
import Smartcontractlist from "./Pages/ExplorerNew/Smartcontractlist/index";
import SmartcontractDetails from "./Pages/SmartcontractDetails/index";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ExplorerLayout from "./Components/Layouts/ExplorerLayout";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/explorer" element={<Explorer />} />
          <Route element={<ExplorerLayout />}>
            {/* <Route path="/explorer" element={<Explorer2 />} /> */}
            <Route path="/transactions/:txHash" element={<Transactions />} />
            <Route path="/networklist" element={<Networks />} />
            <Route path="/blocks" element={<Blocks />} />
            <Route path="/chain-codes" element={<ChainCodes />} />
            <Route path="/channels" element={<Channels />} />
            <Route path="/latestTransactions" element={<Latest />} />
            <Route path="/transactionDetail" element={<TransactionDetail />} />
            <Route path="/smartcontractlist" element={<Smartcontractlist />} />
            <Route
              path="/smartcontractdetails"
              element={<SmartcontractDetails />}
            />
          </Route>

          {/* <Route element={<PrivateRoutes />}>
        <Route element={<Layout />}>
          {authProtectedLayoutRoutes?.map((item, i) => (
            <Route key={i} path={item.path} element={<item.component />} />
          ))}
        </Route>
      </Route> */}
        </Routes>
      </BrowserRouter>
      {/* <LandingPage /> */}
    </>
  );
};

export default App;
