import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ExplNavbar = () => {
  return (
    <Navbar expand="lg" className="bg-white">
      <Container>
        <Navbar.Brand href="/">
          Faction Ai <span className="highlighted_text"> Thunder Hawk</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto gap-3">
            <NavLink className="nav-link" to="/explorer">
              DASHBOARD
            </NavLink>
            <NavLink className="nav-link" to="/networklist">
              NETWORK
            </NavLink>
            <NavLink className="nav-link" to="/blocks">
              BLOCKS
            </NavLink>
            <NavLink className="nav-link" to="/latestTransactions">
              TRANSACTIONS
            </NavLink>
            <NavLink className="nav-link" to="/smartcontractlist">
              SMART CONTRACTS
            </NavLink>
            <NavLink className="nav-link" to="/chain-codes">
              CHAINCODES
            </NavLink>
            <NavLink className="nav-link" to="/channels">
              CHANNELS
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ExplNavbar;
