import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Images from "../../Assets/Images";

const Counts = () => {
  const data = [
    { imagesrc: Images.counts_shinami },
    { imagesrc: Images.counts_supra },
    { imagesrc: Images.counts_logo },
    { imagesrc: Images.counts_circle },
    { imagesrc: Images.counts_aftermath },
    { imagesrc: Images.counts_circle },
    { imagesrc: Images.counts_hyperspace },
    { imagesrc: Images.counts_circle },
    { imagesrc: Images.counts_shinami },
    { imagesrc: Images.counts_bluefin },
  ];

  return (
    <React.Fragment>
      <div className="py-5">
        <Container fluid className="logos_container">
          <Row className="justify-content-between">
            {data?.map((item, i) => (
              <Col xl={1} key={i}>
                <img
                  src={item.imagesrc}
                  alt="counts"
                  className="img-fluid h-100"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Counts;
// import React from "react";
// import { Col, Row } from "react-bootstrap";

// const Counts = () => {
//   const data = [
//     { heading: "29.7M", text: "FEE PAYING ACCOUNTS, ALL TIME" },
//     { heading: "340M", text: "NFTS MINTED" },
//     { heading: "$0.00064", text: "MEDIAN FEE PER TRANSACTION" },
//     { heading: "500+", text: "We Have Completed Projects" },
//   ];

//   return (
//     <React.Fragment>
//       <div className="counts_page">
//         <div className="counts_content common_container">
//           <Row>
//             {data?.map((item, i) => (
//               <Col
//                 xl={3}
//                 key={i}
//                 className="counts_text_content"
//                 data-aos="zoom-in"
//                 data-aos-duration="1000"
//               >
//                 <p className="main_text">{item?.heading}</p>
//                 <p className="sub_text">{item?.text}</p>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Counts;
