import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { access_token, fireToast } from "../../../common/Toaster";
import axios from "axios";
import { BaseUrl } from "../../../Service/Apis/Apis";

function List() {
  const navigate = useNavigate();
  // const LatestBlock = [
  //   {
  //     Smartcontract: "Thunders",
  //     channelName: "Centeralauthority",
  //     path: "-",
  //     TransactionCount: "0",
  //     chainCode: "_lifecycle",
  //     Version: "2.0",
  //   },
  //   {
  //     Smartcontract: "Thunders",
  //     channelName: "Centeralauthority",
  //     path: "-",
  //     TransactionCount: "0",
  //     chainCode: "_lifecycle",
  //     Version: "2.0",
  //   },
  //   {
  //     Smartcontract: "Thunders",
  //     channelName: "Centeralauthority",
  //     path: "-",
  //     TransactionCount: "0",
  //     chainCode: "_lifecycle",
  //     Version: "2.0",
  //   },
  //   {
  //     Smartcontract: "Thunders",
  //     channelName: "Centeralauthority",
  //     path: "-",
  //     TransactionCount: "0",
  //     chainCode: "_lifecycle",
  //     Version: "2.0",
  //   },
  //   {
  //     Smartcontract: "Thunders",
  //     channelName: "Centeralauthority",
  //     path: "-",
  //     TransactionCount: "0",
  //     chainCode: "_lifecycle",
  //     Version: "2.0",
  //   },
  // ];

  const [listing, setListing] = useState([]);
  const getContractInfo = async () => {
    try {
      const responsedata = await axios.get(BaseUrl + `api/admin/chaincode`, {
        headers: {
          Authorization: access_token,
        },
      });
      const resp = responsedata?.data;
      if (resp.status === 200) {
        setListing(resp.data);
        console.log(resp, "<<--------------------- resp");
      } else {
        fireToast("error", resp.error);
      }
    } catch (error) {
      console.log(error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getContractInfo();
  }, []);

  return (
    <>
      <Container>
        <div className="border border_grey rounded-3 bg-white shadow-sm ml-4 p-3 my-5">
          {/* <div className="text-start pb-4 fw-semibold">LATEST BLOCKS</div> */}
          <table className="table table-striped border border_grey text-center">
            <thead>
              <tr>
                <th scope="col">Smartcontract Name</th>
                <th scope="col">Channel Name</th>
                <th scope="col">Path</th>
                <th scope="col">Transaction Count</th>
                <th scope="col">Version</th>
              </tr>
            </thead>
            <tbody>
              {listing &&
                listing.map((e, index) => (
                  <tr key={index}>
                    <td>
                      <p
                        className="font-size-banner-entries mb-1"
                        onClick={() => navigate("/smartcontractdetails")}
                      >
                        {e.chaincodename}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.channelName}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">{e.path}</p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.txCount}
                      </p>
                    </td>
                    <td>
                      <p className="font-size-banner-entries mb-1">
                        {e.version}
                      </p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <Button className="w-100 bg-light text-dark font-size-banner-entries">
            View All Latest Transactions
          </Button> */}
        </div>
      </Container>
    </>
  );
}

export default List;
