import React from "react";
import community from "../../Assets/Images/community.png";
import { Row, Col } from "react-bootstrap";

const Community = () => {
  return (
    <div className="blog_page technology_page common_padding white_content">
      {/* <div className="common_padding white_content"> */}

      <div className="common_container">
        <div className="technology">
          {" "}
          <Row>
            <Col xl={7} className="m-auto">
              <div className="tech_content">
                <div className="mb-4">
                  {/* <p className="common_p">
                  <span className="blog_heading headig_text"><h1>Join a community of millions.</h1></span>
                </p> */}
                  {/* <p className="common_p"> */}
                  <span className="blog_heading headig_text text-start">
                    <h1>
                      <b>
                        Join a community <br />
                        of millions.
                      </b>
                    </h1>
                  </span>
                  <Row className="text-start mt-5">
                    <Col xl={6}>
                      {" "}
                      <p className="main_text">29.7M</p>
                      <p className="sub_text">TRANSITION PER MINUTE</p>
                    </Col>
                    <Col xl={6}>
                      <p className="main_text">340M+</p>
                      <p className="sub_text">USERS</p>
                    </Col>
                  </Row>
                  <Row className="text-start mt-3">
                    <Col xl={6}>
                      {" "}
                      <p className="main_text">$0.00064</p>
                      <p className="sub_text">FEE PER TRANSACTION</p>
                    </Col>
                    <Col xl={6}>
                      <p className="main_text">1.3M</p>
                      <p className="sub_text">PROJECTS BUILD</p>
                    </Col>
                  </Row>
                  {/* </p> */}
                </div>
              </div>
            </Col>
            <Col xl={5}>
              <img
                alt="technology"
                className="img-fluid rounded"
                src={community}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Community;
