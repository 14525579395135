import React, { useEffect, useState } from "react";
import mark from "../../Assets/Images/mark.svg";
import { Container } from "react-bootstrap";
import { access_token, fireToast } from "../../common/Toaster";
import axios from "axios";
import { BaseUrl } from "../../Service/Apis/Apis";
function Details() {
  // const query = new URLSearchParams(this.props.location.search);
  let txHash = new URLSearchParams(window.location.search).get("id");

  const [info, setInfo] = useState(null);
  const getTransactionInfo = async () => {
    try {
      const responsedata = await axios.get(
        BaseUrl + `api/admin/transactiondetails?txhash=${txHash}`,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );
      const resp = responsedata?.data;
      if (resp.status === 200) {
        setInfo(resp.data);
        console.log(resp, "<<--------------------- resp");
      } else {
        fireToast("error", resp.error);
      }
    } catch (error) {
      console.log(error);
      fireToast("error", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getTransactionInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bg-color  padding-bottom border">
        <Container>
          <div className="d-flex my-5 gap-5  ">
            <div>Transaction Details</div>
          </div>

          <div className="mt-3 p-3 border rounded bg-white shadow-sm font-size text-gray">
            <div className="d-flex padding-left1 padding-right1 align-items-center  mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Transaction ID:</p>
              </div>
              <div className="col-9">{info?.txhash}</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Validation Code:</p>
              </div>
              <div className="col-9">{info?.validation_code}</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Payload Proposal Hash:</p>
              </div>
              <div className="col-9 text-pink ">
                {info?.payload_proposal_hash}
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Creator MSP:</p>
              </div>
              <div className="col-9 ">{info?.creator_msp_id}</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Endorser:</p>
              </div>
              <div className="col-9 ">
                <span>{info?.endorser_msp_id}</span>
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Smart Contract Name:</p>
              </div>
              <div className="col-9 ">
                <span className="text-pink">{info?.chaincodename}</span>
              </div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Type:</p>
              </div>
              <div className="col-9 ">{info?.type}</div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Time:</p>
              </div>
              <div className="col-9 ">{info?.createdt}</div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Details;
