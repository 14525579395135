import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Blocks = () => {
  const navigate = useNavigate();
  const listing = [
    {
      server_hostname: "peer0.org2.example.com:",
      peer_type: "Peer",
      mspid: "Org2MSP",
      ledger_height_high: "0",
      ledger_height_low: "16",
      ledger_height_unsigned: "True",
    },
    {
      server_hostname: "peer0.org2.example.com:",
      peer_type: "Peer",
      mspid: "Org2MSP",
      ledger_height_high: "0",
      ledger_height_low: "16",
      ledger_height_unsigned: "True",
    },
    {
      server_hostname: "peer0.org2.example.com:",
      peer_type: "Peer",
      mspid: "Org2MSP",
      ledger_height_high: "0",
      ledger_height_low: "16",
      ledger_height_unsigned: "True",
    },
  ];

  return (
    <React.Fragment>
      <div className="blocks_page py-5">
        <Container>
          <div className="rounded-3 bg-white  ml-4 p-3">
            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th scope="col">Peer Name</th>
                  <th scope="col">Request URL</th>
                  <th scope="col">Peer Type</th>
                  <th scope="col">MSPID</th>
                  <th scope="col">High</th>
                  <th scope="col">Low</th>
                  <th scope="col">Unsigned</th>
                </tr>
              </thead>
              <tbody>
                {listing &&
                  listing.map((e, index) => (
                    <tr key={index}>
                      <td>
                        <p
                          className="font-size-banner-entries mb-1 main_heading"
                          onClick={() => navigate("/smartcontractdetails")}
                        >
                          {e.server_hostname}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.server_hostname}
                        </p>
                      </td>
                      <td>
                        <p className=" blocks_page_td font-size-banner-entries mb-1">
                          {e.peer_type}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.mspid}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.ledger_height_high}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.ledger_height_low}
                        </p>
                      </td>
                      <td>
                        <p className="blocks_page_td font-size-banner-entries mb-1">
                          {e.ledger_height_unsigned}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Blocks;
