// import moment from "moment"
import Swal from "sweetalert2";

export const toastConfig = () => {
  return Swal.mixin({
    customClass: {
      container: "sweet-toast",
      confirmButton: "btn btn-success",
    },
    buttonsStyling: false,
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    confirmButtonText: "close",
    confirmButtonColor: "white",
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export const toastConfirm = (text, subText, html = "") => {
  return Swal.mixin({
    title: text ? text : "Are you sure?",
    text: subText ? subText : "You wanna proceed?",
    html: html,
    customClass: {
      container: "sweet-toast",
    },
    showCancelButton: true,
    confirmButtonText: "Yes",
    denyButtonText: "No",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export const fireToast = (icon, title) => {
  toastConfig().fire({
    icon,
    title,
  });
};

export const fireSuccessToast = (title) => {
  fireToast("success", title);
};

export const access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiZXhwbG9yZXJhZG1pbiIsIm5ldHdvcmsiOiJ0ZXN0LW5ldHdvcmsiLCJpYXQiOjE3MjYyMzE5NjYsImV4cCI6MTcyNjIzOTE2Nn0.Hc9onIYv9TZm-KndiXqgMmZTY2U3JC8MBJ1eZ9NdAJk';

export const timeFormat = async (time) => {
    return time;
    // return moment(time).format('d M, Y H:i A')
}