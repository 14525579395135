import React from "react";
import logo from "../../Assets/Images/short-logo.png";
import { IoSettingsOutline } from "react-icons/io5";
import { Container } from "react-bootstrap";

const Header = () => {
  return (
    <div className="block_exp_header mt-3">
      <Container className=" d-flex justify-content-between ">
        <div className="left_content d-flex">
          <img src={logo} height={30} alt="logo" />
          <p className="common_p">
            Faction Price:{" "}
            <span className="highlighted_text">
              <b>$ 0.464+2.16 %</b>{" "}
            </span>
          </p>
        </div>
        <div className="right_content d-flex">
          <input
            className="form-control common_input_content"
            placeholder="Search by Address / Txn Hash / Block / Token"
          />
          <div className="common_input_icon ms-2">
            <IoSettingsOutline />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
